<template>
  <v-container fluid class="pa-5">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Movimentação Cadastral</h1>
        <h2 class="secondary--text font-weight-regular">Reativação</h2>
      </v-col>
    </v-row>
    <h2 class="mt-6 mb-2 secondary--text font-weight-regular">
      Pesquisa Beneficiário
    </h2>
    <v-row>
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <v-btn
          class="white--text"
          :outlined="search.type === 'dependent'"
          medium
          block
          color="textPrimary"
          @click="search.type = 'holder'; results = {}"
        >
          Titular
        </v-btn>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <v-btn
          class="white--text"
          :outlined="search.type === 'holder'"
          medium
          block
          color="textPrimary"
          @click="search.type = 'dependent'; results = {}"
        >
          Dependente
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="formRegister" v-model="isValidForm">
      <v-row class="mt-10 align-center">
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="search.financialGroupsIds"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            :loading="loadingFinancialGroups"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierId ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="search.insuranceCarrierId"
            :items="insuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            :loading="loadingInsuranceCarriers"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.financialGroupsIds ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Contrato</label>
          <v-autocomplete
            v-model="search.contractsIds"
            :items="contracts"
            item-value="id"
            :loading="loadingContracts"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierId ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
      </v-row>
      <v-row class="align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">{{ search.type === 'holder' ? 'CPF do Titular' : 'CPF do Dependente' }}</label>
          <v-text-field
            v-model="search.physicalPersonCPF"
            class="mt-2"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            :rules="search.physicalPersonCPF ? [rule.cpf] : []"
            outlined
            required
            color="textPrimary"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">{{ search.type === 'holder' ? 'Nome do Titular' : 'Nome do Dependente' }}</label>
          <v-text-field
            v-model.trim="search.physicalPersonName"
            :rules="search.physicalPersonName ? [rule.name, rule.validateIsAlphanumeric] : []"
            class="mt-2"
            placeholder="Informe o nome"
            maxlength="70"
            outlined
            color="textPrimary"
            required
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Matrícula</label>
          <v-text-field
            maxlength="50"
            v-model="search.registration"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :loading="loadingBeneficiary"
            :disabled="isDisableFieldFilters"
            @click.prevent="filter('filter')"
          >
            Localizar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn color="textPrimary" x-large text @click="clearFields()">
            Limpar campos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <HolderSearchTable
        v-if="search.type === 'holder'"
        :responseData="results"
        :loadingBeneficiary="loadingBeneficiary"
        movementType="reativation"
        @setPagination="setPagination"
        @onClickHandler="onClickReactivate"
      />
      <DependentSearchTable
        v-else
        :responseData="results"
        :byAcordeon="false"
        :loadingBeneficiary="loadingBeneficiary"
        movementType="reativation"
        @setPagination="setPagination"
        @onClickHandler="onClickReactivate"
      />
    </v-row>

    <v-footer class="my-10 pr-0">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickCancel()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="120"
      >
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <v-dialog :value="confirmationDialog.show" persistent max-width="1080">
      <v-card class="pa-5">
        <v-form ref="formModal">
          <v-card-title class="headline pl-3 ml-3">
            <v-row>
              <v-col
                class="text-h4"
                cols="6"
                style="color: #3b495b; font-weight: bold">Reativar Grupo Familiar</v-col>
              <v-col class="d-flex justify-end align-center" cols="6">
                <v-icon
                  large
                  color="textPrimary"
                  @click="onClickCloseModal()"
                  >fas fa-times</v-icon
                >
              </v-col>
              <v-col class="text-h5" cols="12" style="color: #3b495b">
                Preencha os campos abaixo para concluir a reativação.
              </v-col>
              <v-col
                class="mt-8 text-h6 font-weight-regular"
                cols="12"
                style="color: #3b495b"
                >Motivo da reativação
                <v-textarea
                  class="mt-2"
                  v-model="confirmationDialog.reason"
                  outlined
                  name="input-7-4"
                  placeholder="Digite aqui"
                  color="textPrimary"
                ></v-textarea
              ></v-col>
            </v-row>
          </v-card-title>

          <v-card-title>
            <v-row v-if="documentsList.length > 0"  align="center" class="pr-4">
              <v-col cols="12">
                <v-row class="mt-5 pr-4">
                  <v-col cols="12" md="6" align="start">
                    <p class="title">Upload de Documentos</p>
                  </v-col>
                </v-row>
                <v-row class="mb-3 ml-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
                  <v-col v-for="(item, index) in documentsList" :key="index" cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                    <label class="label"> {{ item.description }}  </label>
                      <v-file-input
                        v-model="item.file"
                        @change="uploadDocument(item.id, item.file)"
                        label="Clique para adicionar"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        color="textPrimary"
                        outlined
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip small label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-actions class="mt-10">
            <v-row class="mt-6">
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-btn
                  class="mr-3 pa-7 btn-dialog"
                  color="#3B495B"
                  outlined
                  block
                  @click="onClickCloseModal()"
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-btn
                  class="mr-6 pa-7 btn-dialog"
                  color="#3B495B"
                  block
                  :disabled="!confirmationDialog.reason"
                  style="color: white"
                  @click="onClickConfirmModal()"
                >
                  Reativar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon text dark v-bind="attrs" @click="snackbar.show = false">
          close
        </v-icon>
      </template>
    </v-snackbar>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <v-row class="mb-3" justify="center" slot="textInfo" style="word-break: break-word">
        <v-col cols="12" align="center">
          <label class="label primary--text font-italic" style="word-break: break-word">“Ops! Algo deu errado. Isso pode ter acontecido por você ter selecionado um grande volume de informações. Tente refinar os filtros e repetir a operação”</label>
        </v-col>
      </v-row>
    </DynamicDialogInformation>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DependentContractedPlanService from '@/services-http/contract/DependentContractedPlanService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import HolderSearchTable from '@/components/Beneficiary/Holder/HolderSearchTable.vue';
import DependentSearchTable from '@/components/Beneficiary/Dependent/DependentSearchTable.vue';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import lodash from 'lodash';
import ContractService from '@/services-http/contract/ContractService';

export default {
  name: 'ManualMovementSearchHolderDependentReactivation',
  components: { DynamicDialogInformation, HolderSearchTable, DependentSearchTable },
  data: () => ({
    overlay: false,
    confirmationDialog: {
      show: false,
      validity: null,
      reason: null,
    },
    payload: {},
    loadingBeneficiary: false,
    results: {},
    expanded: [],
    page: 1,
    totalPages: 0,
    isValidForm: true,
    isRHProtegido: false,
    uploadInformationDocuments: {
      movementType: 'INSERT',
      insuranceCarrierId: '',
      beneficiaryType: '',
    },
    documentsList: [],
    documentsData: [],
    search: {
      financialGroupsIds: null,
      insuranceCarrierId: null,
      contractsIds: null,
      registration: null,
      physicalPersonCPF: null,
      physicalPersonName: null,
      type: 'holder',
    },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    financialGroups: [],
    contracts: [],
    insuranceCarriers: [],
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingContracts: false,
    isDisableFieldFilters: true,
    widthModal: 800,
    heightCard: 600,
  }),

  async mounted() {
    this.getInsuranceCarriers();
    await this.getFinancialGroups();
    if (this.financialGroups && this.financialGroups.length === 1) {
      this.search.financialGroupsIds = this.financialGroups[0].id;
      this.verifyFieldsFilters();
    }
  },

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    ContractsMixin,
    InsuranceCarriersMixin,
  ],

  watch: {
    'search.financialGroupsIds': {
      deep: true,
      handler(val) {
        const financialGroupIds = lodash.cloneDeep(val);
        if (financialGroupIds) {
          this.getContractsByFinancialGroupId(financialGroupIds);
        }
      },
    },
  },

  methods: {
    setPagination(pagination) {
      this.page = pagination.page;
      this.totalPages = pagination.totalPages;
      this.filter('pagination');
    },
    filter(type) {
      if (this.$refs.formRegister.validate()) {
        if (type === 'filter') this.page = 1;
        if (this.search.financialGroupsIds !== null || this.search.insuranceCarrierId !== null || this.search.contractsIds !== null || this.search.registration !== null || this.search.physicalPersonCPF !== null || this.search.physicalPersonName !== null) {
          const data = this.verifyParams();

          if (this.search.type === 'holder') {
            this.loadBeneficiary(data);
          } else if (this.search.type === 'dependent') {
            this.loadDependent(data);
          }
        }
      } else {
        this.customizeSnackbarMessage('error', 'Preencha corretamente para prosseguir');
      }
    },
    verifyParams() {
      const cpfUnmasked = this.search.physicalPersonCPF ? this.formatter.removeNonDigit(this.search.physicalPersonCPF) : null;
      const isActive = false;
      const page = this.page - 1;
      const size = 10;

      const params = new URLSearchParams();
      if (this.search.financialGroupsIds) {
        params.append('financialGroupsIds', this.search.financialGroupsIds);
      }
      if (this.search.insuranceCarrierId) {
        params.append('insuranceCarrierId', this.search.insuranceCarrierId);
      }
      if (this.search.contractsIds) {
        params.append('contractsIds', this.search.contractsIds);
      }
      if (this.search.registration) {
        params.append('registration', this.search.registration);
      }
      if (this.search.physicalPersonCPF) {
        params.append('physicalPersonCPF', cpfUnmasked);
      }
      if (this.search.physicalPersonName) {
        params.append('physicalPersonName', this.search.physicalPersonName);
      }
      params.append('isActive', isActive);
      params.append('page', page);
      params.append('size', size);

      return params;
    },
    async loadBeneficiary(params) {
      this.loadingBeneficiary = true;
      await this.beneficiaryContractedPlanService.FindAllByFilters(params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          this.results = response.data;
        }
      }).catch((error) => {
        this.loadingBeneficiary = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    async loadDependent(params) {
      this.loadingBeneficiary = true;
      await this.dependentContractedPlanService.FindAllByFilters(params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          this.results = response.data;
        }
      }).catch((error) => {
        this.loadingBeneficiary = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    verifyFieldsFilters() {
      if (this.search.financialGroupsIds || this.search.insuranceCarrierId || this.search.contractsIds || this.search.registration || this.search.physicalPersonCPF || this.search.physicalPersonName) {
        this.isDisableFieldFilters = false;
      } else {
        this.isDisableFieldFilters = true;
      }
    },
    async onClickReactivate(item) {
      this.overlay = true;
      await this.contractService.FindById(item.contractId).then(async (response) => {
        this.uploadInformationDocuments.insuranceCarrierId = response.data.carrier_id.id;
        if (item.isBeneficiary && !item.isDependent) {
          this.uploadInformationDocuments.movementType = 'REACTIVATION';
          this.uploadInformationDocuments.beneficiaryType = 'HOLDER';
        } else if (item.isDependent && !item.isBeneficiary) {
          this.uploadInformationDocuments.movementType = 'REACTIVATION_DEPENDENT';
          this.uploadInformationDocuments.beneficiaryType = 'DEPENDENT';
        }
        await this.loadDocumentType();
        this.overlay = false;
      }).catch(() => {
        this.overlay = false;
      });

      this.confirmationDialog.show = true;
      if (item.isBeneficiary) {
        this.payload = {
          beneficiaryType: 'HOLDER',
          contract: item.contractId,
          subcontract: item.subcontractId,
          financialGroup: item.financialGroupId,
          holderCpf: this.formatter.removeNonDigit(item.physicalPersonCPF),
          holderName: item.physicalPersonName,
          insuredCpf: item.parentPhysicalPersonCpf,
          insuredName: item.parentName,
          plan: item.planId,
          reason: null,
          documents: this.documentsData,
          contractPlanId: item.contractPlanId,
        };
      } else if (item.isDependent) {
        this.payload = {
          beneficiaryType: 'DEPENDENT',
          contract: item.contractId,
          subcontract: item.subcontractId,
          financialGroup: item.financialGroupId,
          holderCpf: this.formatter.removeNonDigit(item.physicalPersonCPF),
          holderName: item.physicalPersonName,
          insuredCpf: item.parentPhysicalPersonCpf,
          insuredName: item.parentName,
          plan: item.planId,
          reason: null,
          documents: this.documentsData,
          contractPlanId: item.contractPlanId,
        };
      }
    },
    onClickConfirmModal() {
      this.payload.reason = this.confirmationDialog.reason;
      setTimeout(() => {
        this.reactivateMovementRecordService(this.payload);
      }, 500);
    },
    async reactivateMovementRecordService(movementRecord) {
      await this.movementRecordService.Reactivate(movementRecord).then(() => {
        this.customizeSnackbarMessage('success', 'Reativado com sucesso');
        this.onClickCloseModal();
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar reativar');
      });
    },
    clearFields() {
      this.$refs.formRegister.reset();
      this.contracts = [];
    },
    onClickCancel() {
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'Movement';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    async loadDocumentType() {
      const data = `movementType=${this.uploadInformationDocuments.movementType}&insuranceCarrierId=${this.uploadInformationDocuments.insuranceCarrierId}&beneficiaryType=${this.uploadInformationDocuments.beneficiaryType}`;
      await this.findDocumentType(data);
    },
    async findDocumentType(data) {
      await this.documentTypeService.FindAllDocumentType(data).then(async (response) => {
        this.documentsList = await response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
      });
    },
    uploadDocument(id, file) {
      if (file !== null) {
        this.documentService.UploadDocument(id, file).then((response) => {
          this.documentsData.push({ id: response.data });
        });
      }
    },
    onClickCloseModal() {
      this.confirmationDialog.show = false;
      this.$refs.formModal.reset();
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    contructorContractName(item) {
      let text = '';
      if (item) {
        text = `${item.contractBenefitCarrier ? item.contractBenefitCarrier : '-'} - ${item.contractName ? item.contractName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    contructorSubContractName(item) {
      let text = '';
      if (item) {
        text = `${item.subcontractNumber ? item.subcontractNumber : '-'} - ${item.subcontractBusinessName ? item.subcontractBusinessName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementRecordService = new MovementRecordService();
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.dependentContractedPlanService = new DependentContractedPlanService();
    this.contractService = new ContractService();
  },
};
</script>
